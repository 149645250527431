import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';


const slider1 = new Swiper('.c-slider1__wrap', {
	loop: true,
	slidesPerView: 'auto',
	centeredSlides: true,
	pagination: {
		clickable: true,
		el: '.swiper-pagination',
	},
	breakpoints: {
		320: {
			spaceBetween: 20,
		},
		738: {
			spaceBetween: 57.5,
		},
	},
});

const slider2 = new Swiper('.c-slider2__wrap', {
	slidesPerView: 3,
	loop: true,
	slidesPerView: 'auto',
	centeredSlides: true,
	breakpoints: {
		320: {
			slidesPerView: 1,
			spaceBetween: 20,
		},
		738: {
			spaceBetween: 21,
		},
	},
});
