import $ from 'jquery';

$('.js-accordion').on('click', function () {
	let parent = $(this).parent();
	if (parent.hasClass('is-active')) {
		parent.removeClass('is-active');
		$(this).siblings().slideToggle();
	} else {
		parent.addClass('is-active');
		$(this).siblings().slideToggle();
	}
})
