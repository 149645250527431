import $ from 'jquery';

const bodyHtml = $('body,html');
const body = $('body');

/* ======================================
body fix
====================================== */
let scrollPosi;

function bodyFix() {
	scrollPosi = $(window).scrollTop();

	body.addClass('is-fixed');
	body.css({ top: -scrollPosi });
}

function bodyFixReset() {
	body.removeClass('is-fixed')
	body.removeAttr('style')
	bodyHtml.scrollTop(scrollPosi);
}

/* ======================================
Detect ipad or smartphone
====================================== */
function iPad() {
	return [
		'iPad Simulator',
		'iPad',
	].includes(navigator.platform)
		|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

$(window).on('scroll', function () {
	// $('.js-header').css('left', -$(window).scrollLeft())
})

$(function () {
	const btnMenu = $('.js-header-btn')
	const header = $('.js-header')
	const nav = $('.js-header-nav')

	if (btnMenu.length) {
		btnMenu.on('click', function () {
			header.toggleClass('is-active')

			if (header.hasClass('is-active')) {
				bodyFix()

			} else {
				bodyFixReset()
			}
		})
	}

	if (iPad()) {
		if ($(window).outerWidth() < 1025) {
			body.addClass('is-ipad')
		}
	}

	$(window).on('load resize', function () {
		const hWindow = $(window).innerHeight()

		nav.css('height', hWindow)

		if ($(window).outerWidth() > 737) {
			nav.removeAttr('style')
			header.removeClass('is-active')
		} else {
			nav.css({
				'transition': '0.2s linear',
			});
		}
	})
})
